window.CheckoutAddress = {
  init: () => {
    $(document).ready(function() {
      $("#same-address").click((e) => {
        if ($(e.currentTarget).is(":checked")) {
          $("#billing-address").addClass("d-none");
          $("#billing-address :input").prop('disabled', true);
        }
        else {
          $("#billing-address").removeClass("d-none");
          $("#billing-address :input").prop('disabled', false);
        }
      });
    })
  }
}
