function createSlider(selector) {
  let rangeSliderWidget = document.querySelectorAll(selector);

  for (let i = 0; i < rangeSliderWidget.length; i++) {
    let rangeSlider = rangeSliderWidget[i].querySelector(
        ".cz-range-slider-ui"
      ),
      valueMinInput = rangeSliderWidget[i].querySelector(
        ".cz-range-slider-value-min"
      ),
      valueMaxInput = rangeSliderWidget[i].querySelector(
        ".cz-range-slider-value-max"
      );

    let options = {
      dataStartMin: parseInt(rangeSliderWidget[i].dataset.startMin, 10),
      dataStartMax: parseInt(rangeSliderWidget[i].dataset.startMax, 10),
      dataMin: parseInt(rangeSliderWidget[i].dataset.min, 10),
      dataMax: parseInt(rangeSliderWidget[i].dataset.max, 10),
      dataStep: parseInt(rangeSliderWidget[i].dataset.step, 10),
      dataUnit: rangeSliderWidget[i].dataset.unit
    };

    noUiSlider.create(rangeSlider, {
      start: [options.dataStartMin, options.dataStartMax],
      connect: true,
      step: options.dataStep,
      pips: { mode: "count", values: 5 },
      tooltips: true,
      range: {
        min: options.dataMin,
        max: options.dataMax,
      },
      format: {
        to: function (value) {
          return `${parseInt(value, 10)}${options.dataUnit}`;
        },
        from: function (value) {
          return Number(value);
        },
      },
    });

    rangeSlider.noUiSlider.on("update", (values, handle) => {
      let value = values[handle];
      value = value.replace(/\D/g, "");
      if (handle) {
        valueMaxInput.value = Math.round(value);
      } else {
        valueMinInput.value = Math.round(value);
      }
    });

    valueMinInput.addEventListener("change", function () {
      rangeSlider.noUiSlider.set([this.value, null]);
    });

    valueMaxInput.addEventListener("change", function () {
      rangeSlider.noUiSlider.set([null, this.value]);
    });
  }
}

function resetSlider(selector) {
  let rangeSliderWidget = document.querySelectorAll(selector);
  for (let i = 0; i < rangeSliderWidget.length; i++) {
    let rangeSlider = rangeSliderWidget[i].querySelector(
        ".cz-range-slider-ui"
      ),
      valueMin = rangeSliderWidget[i].dataset.min,
      valueMax = rangeSliderWidget[i].dataset.max;

    rangeSlider.noUiSlider.set([valueMin, valueMax]);
  }
}

function initMap(selector) {
  $(selector).each(function (i) {
    let coords = new google.maps.LatLng(
      $(this).data("lat"),
      $(this).data("long")
    );
    let options = {
      center: coords,
      zoom: 14,
    };
    let map = new google.maps.Map(document.getElementById(this.id), options);

    let marker = new google.maps.Marker({
      position: coords,
      map: map,
    });
  });
}

export const Helper = {
  createSlider, resetSlider, initMap
}