import { Helper } from "../helpers.js";

window.SellerProfile = {
  init: () => {
    $(document).ready(() => {
      Helper.initMap(".seller-map");

      $("#sort").change((e) => {
        $("#btn-submit-search-form").click();
      });
    });
  },
};
