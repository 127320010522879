import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["passwordField", "icon"];

  togglePassword(event) {
    if (this.passwordField.type === "password") {
      this.passwordField.type = "text";
      $(this.icon).removeClass("fe-eye").addClass("fe-eye-off");
    } else {
      this.passwordField.type = "password";
      $(this.icon).removeClass("fe-eye-off").addClass("fe-eye");
    }
  }

  get passwordField() {
    return this.passwordFieldTarget;
  }

  get icon() {
    return this.iconTarget;
  }
}
