export function base64ToFile(dataURI, origFile) {
  var byteString, mimestring;

  if (dataURI.split(",")[0].indexOf("base64") !== -1) {
    byteString = atob(dataURI.split(",")[1]);
  } else {
    byteString = decodeURI(dataURI.split(",")[1]);
  }

  mimestring = dataURI.split(",")[0].split(":")[1].split(";")[0];

  var content = new Array();
  for (var i = 0; i < byteString.length; i++) {
    content[i] = byteString.charCodeAt(i);
  }

  var newFile = new File([new Uint8Array(content)], origFile.name, {
    type: mimestring,
  });

  // Copy props set by the dropzone in the original file

  var origProps = [
    "upload",
    "status",
    "previewElement",
    "previewTemplate",
    "accepted",
  ];

  $.each(origProps, function (i, p) {
    newFile[p] = origFile[p];
  });

  return newFile;
}

export function createCropperModal() {
  const modal = `
      <div class="modal" aria-modal="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Crop Image</h5>
              <button type="button" class="close close-button" aria-label="Close" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body"></div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary close-button" data-dismiss="modal">Close</button>
              <button id="confirmButton" type="button" class="btn btn-primary">Confirm</button>
            </div>
          </div>
        </div>
      </div>
    `;

  return modal;
}
