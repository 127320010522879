import { Helper } from "../helpers.js";

window.ProductDetail = {
  init: () => {
    $(document).ready(() => {
      $(".input-add-on").change((e) => {
        let input = $(e.currentTarget);
        if (input.is(":checked")) {
          input.prop("checked", false);
          $(input.data("target")).modal("show");
        } else $("#btn-submit-price-form").click();
      });

      $(".sub-add-on-option").change((e) => {
        let input = $(e.currentTarget);
        if (input.is(":checked")) {
          $(input.data("parent-id")).val(input.data("value"));
        }
      });

      $(".sub-add-on-option").change((e) => {
        let input = $(e.currentTarget);
        let id = "#modal" + input.attr("id");
        $('.sub-add-on-option').each(function(i, el) {
          if (el.closest(".custom-radio").classList.contains('active')) {
            return $(el).closest(".custom-radio").removeClass('active');
          }
        });
        if($(input.attr("id")).prop("checked", true)) {
          $(id).addClass("active");
        }
      });

      $(".btn-add-value-add-ons").click((e) => {
        let input = $(e.currentTarget);
        if (
          !$(`input:radio[name='${input.data("radio_target")}']`).length ||
          $(`input:radio[name='${input.data("radio_target")}']:checked`).val()
        ) {
          $(input.data("input_target")).prop("checked", true);
          input.parents(".modal").modal("hide");
          $("#btn-submit-price-form").click();
        }
      });

      Helper.initMap(".seller-map");

      $(".variant-select, #quantity").change((e) => {
        $("#btn-submit-price-form").click();
      });

      $('[data-toggle="tooltip"]').tooltip();
      var clipboard = new Clipboard('.clipboard-btn');

      $(".clipboard-btn").click(function(){
        var tooltip = $(this);
        tooltip.attr('data-original-title', 'Copied!');
        $(".clipboard-btn").tooltip('show');
      });
    });
  },
};
