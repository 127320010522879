import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const el = this.element;
    el.addEventListener("click", (evt) => this.linkClicked(evt));
  }

  linkClicked(evt) {
    const title = this.data.get("title");
    const json = JSON.parse(this.data.get("json"));
    amplitude.getInstance().logEvent(title, json);
  }
}
