window.QuotationList = {
  init: () => {
    $("body").delegate(".btn-cancel-quotation", "click", (e) => {
      $("#quotation_id").val(e.currentTarget.dataset.quotation_id);
      $("#cancel-quotation-modal").modal("show");
    });

    const $reason = $("#cancellation_reason");
    const $submit = $("#btn-submit-cancel-quotation-form");

    $reason.on("keyup", (evt) => {
      if (evt.target.value.trim().length > 0) {
        $submit.attr("disabled", null);
      } else {
        $submit.attr("disabled", "disabled");
      }
    });

    $submit.click((e) => {
      if ($reason.val()) {
        $.ajax({
          url: Routes.cancel_company_users_quotation_path($("#quotation_id").val()),
          data: {
            cancellation_reason: $reason.val(),
          },
          type: "PUT",
        });
      }
    });
  },
};
