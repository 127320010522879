import { Controller } from "stimulus";

export default class extends Controller {
  requestNewPriceClicked() {
    $.ajax({
      url: `${this.url}?variants=${this.variants.join(",")}`,
      method: "GET",
      dataType: "script",
    });
  }

  get url() {
    return this.data.get("url");
  }

  get variants() {
    const variantIds = $("select[name='variants[]']")
      .map((v, e) => e.value)
      .get();

    return variantIds;
  }
}
