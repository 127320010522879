window.CartMenu = {
  init: () => {
    $("body").delegate(".btn-remove-item", "click", (e) => {
      let url = e.target.dataset.url || Routes.remove_from_cart_products_path()
      const productName = e.currentTarget.dataset.itemName;
      if (productName) {
        amplitude.getInstance().logEvent("Removed Product from Cart", {
          product: productName,
        });
      }

      $.ajax({
        url: url,
        data: {
          cart_item: e.currentTarget.dataset.item,
          value_add_on_id: e.currentTarget.dataset.value_add_on_id,
        },
        type: "DELETE",
      });
    });
  },
};
