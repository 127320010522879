import { Controller } from "stimulus";
import { base64ToFile, createCropperModal } from "../utils/index";
export default class extends Controller {
  connect() {
    var params = {
      authenticity_token: this.authenticityToken,
    };

    this.currentFile = null;
    $(this.element).dropzone({
      url: this.baseUrl,
      method: this.method,
      params: params,
      paramName: "batch[transportation_detail_attributes][transportation_image]",
      autoProcessQueue: false,
      uploadMultiple: false,
      previewsContainer: $("#transportation-detail-dropzone .dz-preview")[0],
      previewTemplate: $("#transportation-detail-dz-preview-template").html(),
      thumbnailHeight: null,
      thumbnailWidth: null,
      acceptedFiles: "image/*",
      maxFiles: 1,
      init: () => this.initDropzone(),
      error: this.onError,
    });
  }

  initDropzone() {
    let dropzone = Dropzone.forElement(this.element);
    let $form = $(this.element).closest("form");
    let that = this;

    $form.on("submit", function (e) {
      let canProcess = true;

      if (that.imageUrl == null || that.imageUrl == "") {
        if (dropzone.files.length == 0) {
          canProcess = false;
          e.preventDefault();
          e.stopPropagation();
          alert ('Please choose a transportation image!')
          return false;
        }
      }
      dropzone.processQueue()
    });

    dropzone.on("addedfile", (file) => this.onAddedFile(file));

    dropzone.on("sending", function (data, xhr, formData) {
      $form
        .find(
          "input[id][name^='batch'], textarea[id][name^='batch'], select[id][name^='batch']"
        )
        .each(function (_index, input) {
          if (input.id == 'batch-certificate-ids') {
            $.each( $(input).select2("val"), ( index, id ) => {
              formData.append('batch[certificate_ids][]', id);
            });
          } else if (input.id == 'batch_is_archived') {
            let is_checked = $('#batch_is_archived').is(':checked') ? 1 : 0;
            formData.append('batch[is_archived]', is_checked);
          } else {
            formData.append(input.name, input.value);
          }
        });
      if (window.batchDetailSelectedFile) {
        formData.append('batch[hero_image]', window.batchDetailSelectedFile);
      }
      if (window.batchProcessingSelectedFile) {
        formData.append('batch[transportation_detail_attributes][processed_image]', window.batchProcessingSelectedFile);
      }
    });
    dropzone.on("success", function (file, response) {
      window.batchDetailSelectedFile = null
      if (file.id) {
        return;
      }

      Turbolinks.clearCache();
      Turbolinks.visit(that.redirectUrl, {
        action: "replace",
      });
    });

    this.setupExistingAttachment();
  }

  transformFile(file, done) {
    let that = this;
    let dropzone = Dropzone.forElement(this.element);
    let $modal = $(createCropperModal());
    let $editor = $(document.createElement("div"));
    $editor.css("backgroundColor", "#000");

    $modal.find(".modal-body").append($editor);
    $modal.find(".close-button").hide();
    $modal.find(".close-button").on("click", function () {
      file.previewElement.remove();
      dropzone.emit("addedfile", that.originalFile);
      dropzone.emit("thumbnail", that.originalFile, that.imageUrl);
      $(that.element).addClass("dz-max-files-reached");
      $modal.modal("hide");
    });

    $modal.find("#confirmButton").on("click", function () {
      // Get the canvas with image data from Cropper.js
      var canvas = cropper.getCroppedCanvas({
        width: 1024,
        height: 1024,
      });
      // Turn the canvas into a Blob (file object without a name)
      canvas.toBlob(function (blob) {
        // Create a new Dropzone file thumbnail
        dropzone.createThumbnail(
          blob,
          dropzone.options.thumbnailWidth,
          dropzone.options.thumbnailHeight,
          dropzone.options.thumbnailMethod,
          false,
          function (dataURL) {
            // Update the Dropzone file thumbnail
            dropzone.emit("thumbnail", file, dataURL);

            var croppedFile = base64ToFile(dataURL, file);
            dropzone.files[0] = croppedFile;
          }
        );
      });
      // Remove the editor from the view
      $modal.modal("hide");
    });

    // Create an image node for Cropper.js
    var image = new Image();
    image.src = URL.createObjectURL(file);
    image.style.display = "block";
    image.style.maxWidth = "100%";
    $editor.append(image);

    // Create Cropper.js
    var cropper = new Cropper(image, {
      aspectRatio: 18 / 11,
      viewMode: 1,
      minContainerWidth: 750,
      minContainerHeight: 400,
    });

    $("body").append($modal);
    $modal.on("hidden.bs.modal", function () {
      $modal.modal("dispose");
      $modal.remove();
    });
    $modal.modal({
      backdrop: "static",
      show: true,
    });
  }

  onAddedFile(file) {
    let dropzone = Dropzone.forElement(this.element);

    var maxFiles = dropzone.options.maxFiles;
    if (maxFiles == 1 && this.currentFile && dropzone.files.length > 1) {
      dropzone.removeFile(dropzone.files[0]);
    }

    this.currentFile = file;

    if (!file.id) {
      this.transformFile(file);
    }
  }

  setupExistingAttachment() {
    if (this.imageUrl == null || this.imageUrl == "") {
      return;
    }
    let dropzone = Dropzone.forElement(this.element);
    let attachment = {
      id: 1,
      filename: "image",
      byte_size: 12345,
      thumbnail_url: this.imageUrl,
    };

    var mockFile = {
      id: attachment.id,
      name: attachment.filename,
      size: attachment.byte_size,
      status: "success",
      type: "image/jpeg",
    };

    this.originalFile = mockFile;

    dropzone.emit("addedfile", mockFile);
    if (attachment.thumbnail_url) {
      dropzone.emit("thumbnail", mockFile, attachment.thumbnail_url);
    }
    dropzone.emit("complete", mockFile);
    dropzone.emit("success", mockFile, {
      id: attachment.id,
    });
    dropzone.emit("uploadprogress", mockFile, 50);
    dropzone.emit("uploadprogress", mockFile, 100);

    $(this.element).addClass("dz-max-files-reached");
  }

  onError(file, errorMessage) {
    file.previewElement.remove();
  }

  get baseUrl() {
    return this.data.get("base-url");
  }

  get authenticityToken() {
    return this.data.get("authenticity-token");
  }

  get imageUrl() {
    return this.data.get("image-url");
  }

  get redirectUrl() {
    return this.data.get("redirect-url");
  }

  get method() {
    return this.data.get("method");
  }
}
