import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["price", "quantity", "updateBtn", "batch"];

  update() {
    $.ajax({
      url: this.data.get("url"),
      method: "PATCH",
      dataType: "script",
      data: this.formData(),
    });
  }

  formData() {
    if (this.entityType == "order-item") {
      return {
        order_item: {
          price: this.price,
          quantity: this.quantity,
          batch_id: this.batchId
        },
      };
    } else {
      return {
        order_item: {
          order_item_add_ons_attributes: [
            {
              id: this.entityID,
              price: this.price,
            },
          ],
        },
      };
    }
  }

  get entityType() {
    return this.data.get("entity-type");
  }

  get entityID() {
    return this.data.get("entity-id");
  }

  get price() {
    return this.priceTarget.value;
  }

  get quantity() {
    if (this.hasQuantityTarget) {
      return this.quantityTarget.value;
    }
  }

  get batchId() {
    return this.batchTarget.value;
  }

  get updateBtn() {
    return this.updateBtnTarget;
  }
}
