window.CreditApplication = {
  init: () => {
    $(document).ready(function() {
      $("#add-another-shareholder").click((e) => {
        e.preventDefault();
        let shareHolderNumber = parseInt($("#shareholder_number").val());
        let html = `
          <div class='col-sm-8'>
            <div class='form-group'>
              <label class='text-muted' for='shareholder-name-${shareHolderNumber}'> Shareholder Name</label>
              <input class='form-control' name='credit_application[shareholding_structure][][name]' id='shareholder-name-${shareHolderNumber}' type='text'>
            </div>
          </div>
          <div class='col-sm-4'>
            <div class='form-group'>
              <label class='text-muted' for='percent-ownership-${shareHolderNumber}'> % of Ownership</label>
              <input class='form-control' name='credit_application[shareholding_structure][][percent_ownership]' id='percent-ownership-${shareHolderNumber}' type='text'>
            </div>
          </div>
        `
        $(".shareholder").append(html);
        $("#shareholder_number").val(shareHolderNumber + 1);
      })

      $("#add-another-director").click((e) => {
        e.preventDefault();
        let directorNumber = parseInt($("#director_number").val());
        let html = `
          <div class='col-12'>
            <div class='form-group'>
              <label class='text-muted' for='director-name-${directorNumber}'> Director Name</label>
              <input class='form-control' name='credit_application[list_of_directors][][name]'s id='director-name-${directorNumber}' type='text'>
            </div>
          </div>
        `
        $(".director").append(html);
        $("#director_number").val(directorNumber + 1);
      })

      $("#add-another-branches-warehouses").click((e) => {
        e.preventDefault();
        let branchesWarehousesNumber = parseInt($("#branches_warehouses_number").val());
        let html= `
          <div class='col-12'>
            <div class='form-group'>
              <label class='text-muted' for='branches-warehouses-address-${branchesWarehousesNumber}'>Address</label>
              <input class='form-control' name='credit_application[branches_and_warehouses][${branchesWarehousesNumber}][address]' id='branches-warehouses-address-${branchesWarehousesNumber}' type='text'>
            </div>
          </div>
          <div class='col-12'>
            <div class='form-group'>
              <div class='custom-control custom-radio custom-control-inline'>
                <input class='custom-control-input' id='branches-warehouses-rented-${branchesWarehousesNumber}' value="rented" name='credit_application[branches_and_warehouses][${branchesWarehousesNumber}][type]' type='radio'>
                <label class='custom-control-label' for='branches-warehouses-rented-${branchesWarehousesNumber}'>Rented</label>
              </div>
              <div class='custom-control custom-radio custom-control-inline'>
                <input class='custom-control-input' id='branches-warehouses-owned-${branchesWarehousesNumber}' value="owned" name='credit_application[branches_and_warehouses][${branchesWarehousesNumber}][type]' type='radio'>
                <label class='custom-control-label' for='branches-warehouses-owned-${branchesWarehousesNumber}'>Owned</label>
              </div>
            </div>
          </div>
        `
        $(".branches-warehouses").append(html);
        $("#branches_warehouses_number").val(branchesWarehousesNumber + 1);
      })
    })
  }
}