import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["timeInput", "daySwitch"];

  connect() {
    $(this.daySwitch).on("change", () => this.daySwitchToggled());
  }

  daySwitchToggled() {
    console.log("Day Switch toggled", this.daySwitch.checked);

    $(this.element)
      .find(".card-header")
      .toggleClass("bg-primary")
      .toggleClass("bg-secondary");
  }

  get timeInput() {
    return this.timeInputTarget;
  }

  get daySwitch() {
    return this.daySwitchTarget;
  }
}
