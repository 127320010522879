import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    var params = {
      authenticity_token: this.authenticityToken,
    };
    params[this.attachmentParam] = this.attachmentParam == 'attachment_type' ? "image" : "product_image";

    $(this.element).dropzone({
      url: this.baseUrl,
      params: params,
      previewsContainer: ".dropzone-previews",
      previewTemplate: $("#dropzoneItemTemplate").html(),
      thumbnailHeight: 250,
      thumbnailWidth: 250,
      acceptedFiles: "image/*",
      init: () => this.initDropzone(),
      error: this.onError,
      success: (file, response) => this.onSuccess(file, response),
      removedfile: (file) => this.onRemovedFile(file),
      transformFile: (file, done) => this.transformFile(file, done),
    });
  }

  initDropzone() {
    let dropzone = Dropzone.forElement(this.element);
    let attachmentType = this.attachmentParam == 'attachment_type' ? "image" : "product_image"

    $.ajax({
      url: `${this.baseUrl}.json?${this.attachmentParam}=${attachmentType}`,
      type: "GET",
      timeout: 30000,
    })
      .done(function (result) {
        result.attachments.forEach((attachment) => {
          setupExistingAttachment(attachment);
        });
      })
      .fail(function () {
        console.log(result);
      });

    function setupExistingAttachment(attachment) {
      var mockFile = {
        id: attachment.id,
        name: attachment.filename,
        size: attachment.byte_size,
        locale: attachment.locale,
        status: "success",
        _removeLink: {},
      };
      dropzone.emit("addedfile", mockFile);
      if (attachment.thumbnail_url) {
        dropzone.emit("thumbnail", mockFile, attachment.thumbnail_url);
      }
      dropzone.emit("complete", mockFile);
      dropzone.emit("success", mockFile, {
        id: attachment.id,
        locale: attachment.locale,
      });
      dropzone.emit("uploadprogress", mockFile, 50);
      dropzone.emit("uploadprogress", mockFile, 100);
    }
  }

  transformFile(file, done) {
    let dropzone = Dropzone.forElement(this.element);
    let homepageModal = dropzone.options.params.hasOwnProperty('attachment_type')
    let $modal = this.createModal();
    let $editor = $(document.createElement("div"));
    $editor.css("backgroundColor", "#000");

    $modal.find(".modal-body").append($editor);
    $modal.find(".close-button").on("click", function () {
      file.previewElement.remove();
      $("#mainModal").modal("hide");
    });

    let setHomepageMediaSize = this.attachmentParam == 'attachment_type'

    $modal.find("#confirmButton").on("click", function () {
      // Get the canvas with image data from Cropper.js
      var options = {}

      // Max size if not for homepage media
      if (setHomepageMediaSize) {
        options = {
          maxWidth: 2000,
        }
      } else {
        options = {
          maxWidth: 1024,
          maxHeight: 1024,
        }
      }

      var canvas = cropper.getCroppedCanvas(options);

      if (homepageModal) {
        dropzone.options.params.attachment_locale = $('input[type=radio]:checked').val()
      }
      // Turn the canvas into a Blob (file object without a name)
      canvas.toBlob(function (blob) {
        // Create a new Dropzone file thumbnail
        dropzone.createThumbnail(
          blob,
          dropzone.options.thumbnailWidth,
          dropzone.options.thumbnailHeight,
          dropzone.options.thumbnailMethod,
          false,
          function (dataURL) {
            // Update the Dropzone file thumbnail
            dropzone.emit("thumbnail", file, dataURL);
            // Return the file to Dropzone
            done(blob);
          }
        );
      });
      // Remove the editor from the view
      $("#mainModal").modal("hide");
    });

    // Create an image node for Cropper.js
    var image = new Image();
    image.src = URL.createObjectURL(file);
    image.style.display = "block";
    image.style.maxWidth = "100%";
    $editor.append(image);

    // Create Cropper.js
    var cropper = new Cropper(image, {
      aspectRatio: this.attachmentParam == 'attachment_type' ? 3.2727 : 1,
      viewMode: 1,
      minContainerWidth: 750,
      minContainerHeight: 400,
    });

    $("#mainModal").html($modal);
    $("#mainModal").modal({
      backdrop: "static",
      show: true,
    });
  }

  onError(file, errorMessage) {
    file.previewElement.remove();
  }

  onSuccess(file, response) {
    let self = this

    $(file.previewElement).find('[data-dz-locale]').text(file.locale)

    if (response.attachment) {
      file.id = response.attachment.id;
    }

    $(file.previewElement).find('.link-media-item-btn').on("click", function () {
      $.ajax({
        url: `${self.baseUrl}/${file.id}/edit`,
        dataType: "script",
      });
    });
  }

  onRemovedFile(file) {
    $.ajax({
      type: "DELETE",
      url: `${this.baseUrl}/${file.id}`,
    });
    file.previewElement.remove();
  }

  createModal() {
    const modal = `
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Crop Image</h5>
            <button type="button" class="close close-button" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body"></div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary close-button">Close</button>
            <button id="confirmButton" type="button" class="btn btn-primary">Confirm</button>
          </div>
        </div>
      </div>
    `;

    return $(modal);
  }

  get baseUrl() {
    return this.data.get("base-url");
  }

  get attachmentParam() {
    return this.data.get("attachment-param");
  }

  get authenticityToken() {
    return this.data.get("authenticity-token");
  }
}
