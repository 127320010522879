// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("trix");
require("@rails/actiontext");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers";
import "pages/product_detail.js";
import "pages/product_list.js";
import "pages/product_preference.js";
import "pages/credit_application.js";
import "pages/checkout_address.js";
import "pages/cart_menu.js";
import "pages/seller_profile.js";
import "pages/user_orders.js";
import "pages/checkout_cart.js";
import "pages/search_batch.js";
import "pages/quotation_list.js";

document.addEventListener("turbolinks:before-cache", function () {
  $('[data-toggle="tooltip"]').tooltip('hide');
});
