import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    var options = {
      delimiter: ",",
      persist: false,
      create: function (input) {
        return {
          value: input,
          text: input,
        };
      },
      onItemAdd: function (value, $item) {
        $item
          .closest("div.form-group")
          .siblings("div.links")
          .find("a.add-value-link")
          .trigger("click");
        $item
          .closest("div.form-group")
          .siblings("input[type='hidden']")
          .last()
          .val(value);
      },
      onItemRemove: function (value) {
        var element = this.$control
          .closest("div.form-group")
          .siblings("input[type='hidden'][value='" + value + "']")[0];

        var id = element.id;
        id = id.replace(id.substring(id.lastIndexOf("_") + 1), "destroy");

        var name = element.name;
        name = name.replace(
          name.substring(name.lastIndexOf("[")),
          "[_destroy]"
        );

        var destroyElement = $(element).clone()[0];
        destroyElement.id = id;
        destroyElement.name = name;
        destroyElement.value = "1";

        $(destroyElement).insertAfter(element);
      },
    };

    $(this.element).selectize(options);
  }
}
