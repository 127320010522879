import { Controller } from "stimulus";

export default class extends Controller {
  confirmClicked() {
    const url = this.url;
    const authenticityToken = this.authenticityToken;
    const message = this.message;
    const methodType = this.methodType;

    Swal.fire({
      text: message,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        $.ajax({
          url: url,
          type: methodType,
          data: { authenticity_token: authenticityToken },
        });
      }
    });
  }

  get url() {
    return this.data.get("url");
  }

  get authenticityToken() {
    return this.data.get("authenticityToken");
  }

  get message() {
    return this.data.get("message") || "Are you sure?";
  }

  get methodType() {
    return this.data.get("methodType");
  }
}
