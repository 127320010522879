window.CheckoutCart = {
  init: (options = {}) => {
    // $("body").delegate(".cart-item-quantity", "change", (e) => {
    //   $.ajax({
    //     url: Routes.update_quantity_products_path(),
    //     data: {
    //       cart_item: e.currentTarget.dataset.item,
    //       quantity: e.currentTarget.value,
    //     },
    //     type: "PUT",
    //   });
    // });

    function updateCartItemQuantity(input) {
      var url = options.updateQtyUrl || Routes.update_quantity_products_path()
      $.ajax({
        url: url,
        data: {
          cart_item: input.data("item"),
          quantity: parseInt(input.val()),
        },
        type: "PUT",
      });
    }

    $(".btn-number").click(function (e) {
      e.preventDefault();
      // Prevent multiple buttons from retriggering
      e.stopPropagation();
      e.stopImmediatePropagation();

      fieldName = $(this).attr("data-field");
      type = $(this).attr("data-type");
      var input = $(this).parents('.qty-component').find("input[name='" + fieldName + "']");
      var step = parseInt(input.attr("step"));
      var min = parseInt(input.attr("min"));
      var max = parseInt(input.attr("max"));
      var currentVal = parseInt(input.val());
      if (!isNaN(currentVal)) {
        if (type == "minus") {
          if (currentVal - step >= min) {
            input.val(currentVal - step).change();
            updateCartItemQuantity(input);
          }
          if (currentVal - step <= min) {
            $(this).attr("disabled", true);
          }
          // debugger;
          const minusAnalyticsMessage = analyticsMessageForPathname(false);
          if (minusAnalyticsMessage) {
            amplitude.getInstance().logEvent(minusAnalyticsMessage);
          }
        } else if (type == "plus") {
          if (currentVal + step <= max) {
            input.val(currentVal + step).change();
            updateCartItemQuantity(input);
          }
          if (currentVal + step >= max) {
            $(this).attr("disabled", true);
          }
          // debugger;
          const plusAnalyticsMessage = analyticsMessageForPathname(true);
          if (plusAnalyticsMessage) {
            amplitude.getInstance().logEvent(plusAnalyticsMessage);
          }
        }
      } else {
        input.val(0);
      }
    });

    function enableButtonsIfInRange(inputNumber) {
      var minValue = parseInt(inputNumber.attr("min"));
      var maxValue = parseInt(inputNumber.attr("max"));
      var stepValue = parseInt(inputNumber.attr("step"));
      var valueCurrent = parseInt(inputNumber.val());

      name = inputNumber.attr("name");
      if (valueCurrent - stepValue >= minValue) {
        $(
          ".btn-number[data-type='minus'][data-field='" + name + "']"
        ).removeAttr("disabled");
      }
      if (valueCurrent + stepValue <= maxValue) {
        $(
          ".btn-number[data-type='plus'][data-field='" + name + "']"
        ).removeAttr("disabled");
      }
    }

    function analyticsMessageForPathname(isIncreasing) {
      const pathname = window.location.pathname;
      if (pathname.endsWith("checkout/cart")) {
        return `Clicked ${
          isIncreasing ? "Increase" : "Decrease"
        } Product Quantity at Cart`;
      } else if (pathname.endsWith("checkout/review")) {
        return `Clicked ${
          isIncreasing ? "Increase" : "Decrease"
        } Product Quantity at Review`;
      } else {
        return null;
      }
    }

    $(".cart-item-quantity").change(function () {
      enableButtonsIfInRange($(this));
    });

    var inputNumbers = $(".cart-item-quantity");
    inputNumbers.each(function () {
      enableButtonsIfInRange($(this));
    });
  },
};
