import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const el = this.element;

    var options = el.dataset.options;
    options = options ? JSON.parse(options) : {};

    flatpickr(el, options);
  }
}
