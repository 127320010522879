window.UserOrders = {
  init: () => {
    $("#filter").change((e) => {
      $("#apply-filters").click();
    });

    $("body").delegate(".btn-cancel-order", "click", (e) => {
      $("#order_id").val(e.currentTarget.dataset.order_id);
      $("#cancel-order-modal").modal("show");
    });

    const $reason = $("#cancellation_reason");
    const $submit = $("#btn-submit-cancel-order-form");

    $reason.on("keyup", (evt) => {
      if (evt.target.value.trim().length > 0) {
        $submit.attr("disabled", null);
      } else {
        $submit.attr("disabled", "disabled");
      }
    });

    $submit.click((e) => {
      if ($reason.val()) {
        $.ajax({
          url: Routes.cancel_order_path($("#order_id").val()),
          data: {
            cancellation_reason: $reason.val(),
          },
          type: "PUT",
        });
      } else {
        alert("Hello, World!");
      }
    });
  },
};
