window.SearchBatch = {
  init() {
    this.handleProductOnClick();
  },


  handleProductOnClick() {
    $(".product-item").on('click', (event) => {
      this.handleChooseProduct(event);
    })
  },

  handleChooseProduct(e) {
    let productEle = $(e.target).closest('.product-item')
    let modalEle = $('#search-batch-modal')

    let productId = productEle.attr('data-product-id')
    let productName = productEle.attr('data-product-name')

    modalEle.find('.search-title').text(`Trace \"${productName}\"`)
    modalEle.find('.search-error').remove()
    modalEle.find('#date').flatpickr().clear()
    modalEle.find('#product_id').val(productId)
    modalEle.modal("show")
  },
};
