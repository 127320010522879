window.ProductPreference = {
  init: () => {
    $(".image-checkbox").each(() => {
      if ($(this).find('input[type="checkbox"]').first().attr("checked")) {
        $(this).addClass('image-checkbox-checked');
      }
      else {
        $(this).removeClass('image-checkbox-checked');
      }
    });

    // sync the state to the input
    $(".image-checkbox").click((e) => {
      $(e.currentTarget).toggleClass('image-checkbox-checked');
      var $checkbox = $(e.currentTarget).find('input[type="checkbox"]');
      $checkbox.prop("checked",!$checkbox.prop("checked"))

      e.preventDefault();
    });
  }
}
