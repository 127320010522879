import { Helper } from "../helpers.js";

window.ProductList = {
  init: () => {
    if (!$(".noUi-base").length)
      Helper.createSlider(".cz-custom-range-slider");

    const $applyFilters = $(".apply-filters").first();

    $(".apply-filters").click((e) => {
      document.querySelector("body").classList.remove("offcanvas-open");
    })

    $("#product-category-group-form").on("keypress", (e) => {
      if (e.keyCode == 13) {
        return false;
      }
    });

    $(".btn-clear-filters").click((e) => {
      window.location.href = window.location.href.split('?')[0];
      $("#product-category-group-form input:not(.search-input)").prop(
        "checked",
        false
      );
      $("#product-category-group-form .search-input").val(null);
      Helper.resetSlider(".cz-custom-range-slider");
      $applyFilters.click();
    });

    $("#sort").change((e) => {
      $applyFilters.click();
    });

    $(".apply_slide_filter").click((e) => {
      $applyFilters.click();
    });

    $(".apply_filter").change((e) => {
      $applyFilters.click();
    });
  }
};
